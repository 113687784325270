<template>
  <g>
    <!-- <g v-if="showVariant === 'outside'"> -->
      <svg:style>
        .cls-101,
        .cls-110,
        .cls-111,
        .cls-112,
        .cls-113,
        .cls-114,
        .cls-115,
        .cls-116,
        .cls-102,
        .cls-103,
        .cls-104,
        .cls-105,
        .cls-106,
        .cls-107,
        .cls-108,
        .cls-109 {
          stroke: #aeaeae;
          stroke-miterlimit: 10;
          stroke-width: 0.25px;
        }
        .cls-101 {
          fill: url(#BG-gradient);
        }
        .cls-102 {
          fill: none;
        }
        .cls-103 {
          fill: url(#handle-gradient);
        }
        .cls-110,
        .cls-111,
        .cls-112,
        .cls-113,
        .cls-114,
        .cls-115,
        .cls-116,
        .cls-104,
        .cls-105,
        .cls-106,
        .cls-107,
        .cls-108,
        .cls-109 {
          stroke-linecap: square;
        }
        .cls-104 {
          fill: url(#inox-gradient);
        }
        .cls-105 {
          fill: url(#inox-gradient-2);
        }
        .cls-106 {
          fill: url(#inox-gradient-3);
        }
        .cls-107 {
          fill: url(#inox-gradient-4);
        }
        .cls-108 {
          fill: url(#inox-gradient-5);
        }
        .cls-109 {
          fill: url(#inox-gradient-6);
        }
        .cls-110 {
          fill: url(#glass-pattern);
        }
        .cls-111 {
          fill: url(#glass-pattern);
        }
        .cls-112 {
          fill: url(#glass-pattern);
        }
        .cls-113 {
          fill: url(#inox-gradient-7);
        }
        .cls-114 {
          fill: url(#inox-gradient-8);
        }
        .cls-115 {
          fill: url(#inox-gradient-9);
        }
        .cls-116 {
          fill: url(#inox-gradient-10);
        }
      </svg:style>
      <linearGradient id="inox-gradient"
                      :x1="doorLeftWidth1 + 86.03"
                      :y1="doorTopHeight1 + 78.49"
                      :x2="doorLeftWidth1 + 117.82"
                      :y2="doorTopHeight1 + 78.49"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-2"
                      data-name="inox-gradient"
                      :x1="doorLeftWidth1 + 86.03"
                      :y1="doorTopHeight1 + 148.21"
                      :x2="doorLeftWidth1 + 117.82"
                      :y2="doorTopHeight1 + 148.21"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-3"
                      data-name="inox-gradient"
                      :x1="doorLeftWidth1 + 86.03"
                      :y1="doorTopHeight1 + 217.94"
                      :x2="doorLeftWidth1 + 117.82"
                      :y2="doorTopHeight1 + 217.94"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-4"
                      data-name="inox-gradient"
                      :x1="inoxX"
                      :y1="doorTopHeight1 + 78.49"
                      :x2="doorLeftWidth1 + 53.8"
                      :y2="doorTopHeight1 + 78.49"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-5"
                      data-name="inox-gradient"
                      :x1="inoxX"
                      :y1="doorTopHeight1 + 148.21"
                      :x2="doorLeftWidth1 + 53.8"
                      :y2="doorTopHeight1 + 148.21"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-6"
                      data-name="inox-gradient"
                      :x1="inoxX"
                      :y1="doorTopHeight1 + 217.94"
                      :x2="doorLeftWidth1 + 53.8"
                      :y2="doorTopHeight1 + 217.94"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="glass-gradient"
                      :x1="doorLeftWidth1 + 70.03"
                      :y1="doorTopHeight1 + 62.37"
                      :x2="doorLeftWidth1 + 70.03"
                      :y2="doorTopHeight1 + 94.6"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#f4f4f4"/>
        <stop offset="0.5" stop-color="#dee8e8"/>
        <stop offset="1" stop-color="#d1cece"/>
      </linearGradient>
      <linearGradient id="glass-gradient-2"
                      data-name="glass-gradient"
                      :x1="doorLeftWidth1 + 70.03"
                      :y1="doorTopHeight1 + 132.1"
                      :x2="doorLeftWidth1 + 70.03"
                      :y2="doorTopHeight1 + 164.33"
                      xlink:href="#glass-gradient"/>
      <linearGradient id="glass-gradient-3"
                      data-name="glass-gradient"
                      :x1="doorLeftWidth1  +70.03"
                      :y1="doorTopHeight1 + 201.82"
                      :x2="doorLeftWidth1 + 70.03"
                      :y2="doorTopHeight1 + 234.05"
                      xlink:href="#glass-gradient"/>
      <linearGradient id="inox-gradient-7"
                      data-name="inox-gradient"
                      :x1="doorLeftWidth1 + 69.8"
                      :y1="doorTopHeight1 + 21.9"
                      :x2="doorLeftWidth1 + 69.8"
                      :y2="doorTopHeight1 + 62.37"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-8"
                      data-name="inox-gradient"
                      :x1="doorLeftWidth1 + 69.8"
                      :y1="doorTopHeight1 + 94.6"
                      :x2="doorLeftWidth1 + 69.8"
                      :y2="doorTopHeight1 + 132.1"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-9"
                      data-name="inox-gradient"
                      :x1="doorLeftWidth1 + 69.8"
                      :y1="doorTopHeight1 + 164.33"
                      :x2="doorLeftWidth1 + 69.8"
                      :y2="doorTopHeight1 + 201.82"
                      xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-10"
                      data-name="inox-gradient"
                      :x1="doorLeftWidth1 + 69.8"
                      :y1="doorTopHeight1 + 234.05"
                      :x2="doorLeftWidth1 + 69.8"
                      :y2="doorTopHeight1 + 275.05"
                      xlink:href="#handle-gradient"/>
    <!-- </g> -->
    <!-- <g v-if="showVariant === 'inside'">
      <svg:style>
        .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
          stroke: #aeaeae;
          stroke-miterlimit: 10;
          stroke-width: 0.25px;
        }

        .cls-4 {
          fill: url(#inox-gradient);
        }

        .cls-5 {
          fill: url(#inox-gradient-2);
        }

        .cls-6 {
          fill: url(#inox-gradient-3);
        }

        .cls-7,
        .cls-8,
        .cls-9 {
          fill: url(#glass-pattern);
        }
      </svg:style>
      <linearGradient id="inox-gradient"
        :x1="doorLeftWidth1 + 70.8"
        :y1="doorTopHeight1 + 171.58"
        :x2="doorLeftWidth1 + 70.8"
        :y2="doorTopHeight1 + 129.18"
        xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-2" data-name="inox"
        :x1="doorLeftWidth1 + 70.8"
        :y1="doorTopHeight1 + 100.91"
        :x2="doorLeftWidth1 + 70.8"
        :y2="doorTopHeight1 + 58.5"
        xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-3" data-name="inox"
        :x1="doorLeftWidth1 + 70.8"
        :y1="doorTopHeight1 + 242.27"
        :x2="doorLeftWidth1 + 70.8"
        :y2="doorTopHeight1 + 199.86"
        xlink:href="#handle-gradient"/>

      <filter id="inset-shadow">
        <feOffset dx="0" dy="0"/>
        <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
        <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
        <feFlood flood-color="black" flood-opacity="1" result="color"/>
        <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
        <feComponentTransfer in="shadow" result="shadow">
          <feFuncA type="linear" slope="1.25"/>
        </feComponentTransfer>
        <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
      </filter>
    </g> -->
    <g id="S26">
      <!-- <g v-if="showVariant === 'outside' && showInox"> -->
        <rect id="inox"
              v-if="showInox"
              class="cls-104"
              :x="doorLeftWidth1 + 86.03"
              :y="doorTopHeight1 + 77.59"
              :width="inoxW"
              height="1.79"/>
        <rect id="inox-2"
              v-if="showInox"
              data-name="inox"
              class="cls-105"
              :x="doorLeftWidth1 + 86.03"
              :y="doorTopHeight1 + 147.32"
              :width="inoxW"
              height="1.79"/>
        <rect id="inox-3"
              v-if="showInox"
              data-name="inox"
              class="cls-106"
              :x="doorLeftWidth1 + 86.03"
              :y="doorTopHeight1 + 217.04"
              :width="inoxW"
              height="1.79"/>
        <rect id="inox-4"
              v-if="showInox"
              data-name="inox"
              class="cls-107"
              :x="inoxX"
              :y="doorTopHeight1 + 77.59"
              :width="inoxW"
              height="1.79"/>
        <rect id="inox-5"
              v-if="showInox"
              data-name="inox"
              class="cls-108"
              :x="inoxX"
              :y="doorTopHeight1 + 147.32"
              :width="inoxW"
              height="1.79"/>
        <rect id="inox-6"
              v-if="showInox"
              data-name="inox"
              class="cls-109"
              :x="inoxX"
              :y="doorTopHeight1 + 217.04"
              :width="inoxW"
              height="1.79"/>
        <rect id="inox-7"
              v-if="showInox"
              data-name="inox"
              class="cls-113"
              :x="doorLeftWidth1 + 68.91"
              :y="inoxY1"
              width="1.79"
              :height="inoxH"/>
        <rect id="inox-8"
              v-if="showInox"
              data-name="inox"
              class="cls-114"
              :x="doorLeftWidth1 + 68.91"
              :y="doorTopHeight1 + 94.6"
              width="1.79"
              height="37.49"/>
        <rect id="inox-9"
              v-if="showInox"
              data-name="inox"
              class="cls-115"
              :x="doorLeftWidth1 + 68.91"
              :y="doorTopHeight1 + 164.33"
              width="1.79"
              height="37.49"/>
        <rect id="inox-10"
              v-if="showInox"
              data-name="inox"
              class="cls-116"
              :x="doorLeftWidth1 + 68.91"
              :y="doorTopHeight1 + 234.05"
              width="1.79"
              :height="inoxH"/>
      <!-- </g> -->
      <!-- <g v-if="showVariant === 'outside'"> -->
        <rect id="glass"
              class="cls-110"
              :x="doorLeftWidth1 + 53.91"
              :y="doorTopHeight1 + 62.37"
              width="32.24"
              height="32.23"/>
        <rect id="glass-2"
              data-name="glass"
              class="cls-111"
              :x="doorLeftWidth1 + 53.91"
              :y="doorTopHeight1 + 132.1"
              width="32.24"
              height="32.23"/>
        <rect id="glass-3"
              data-name="glass"
              class="cls-112"
              :x="doorLeftWidth1 + 53.91"
              :y="doorTopHeight1 + 201.82"
              width="32.24"
              height="32.23"/>
      <!-- </g> -->
      <!-- <g v-if="showVariant === 'inside' && showInox">
        <polyline id="inox" v-if="showInox"
                  data-name="inox"
                  class="cls-4"
                  :points="`${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 129.18} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 129.18} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 171.59} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 171.59} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 129.18}`"/>
        <polyline id="inox-2" v-if="showInox"
                  data-name="inox"
                  class="cls-5"
                  :points="`${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 58.5} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 58.5} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 100.91} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 100.91} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 58.5}`"/>
        <polyline id="inox-3" v-if="showInox"
                  data-name="inox"
                  class="cls-6"
                  :points="`${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 242.27} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 242.27} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 199.87} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 199.87} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 242.27}`"/>
      </g>
      <g v-if="showVariant === 'inside'">
        <polyline filter="url(#inset-shadow)" id="glass"
                  class="cls-7"
                  :points="`${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 63.45} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 63.45} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 95.96} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 95.96} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 63.45}`"/>
        <polyline filter="url(#inset-shadow)" id="glass-2"
                  data-name="glass"
                  class="cls-8"
                  :points="`${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 134.13} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 134.13} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 166.64} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 166.64} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 134.13}`"/>
        <polyline filter="url(#inset-shadow)" id="glass-3"
                  data-name="glass"
                  class="cls-9"
                  :points="`${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 204.81} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 204.81} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 237.32} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 237.32} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 204.81}`"/>
      </g> -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxX() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    },
    inoxH() {
      var hh = this.doorTopHeight1 + 62 - this.doorTopHeight;
      return this.showBg ?
        hh - this.inoxOffset :
        hh - this.leafOffset
    },
    inoxY1() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset:
        this.doorTopHeight + this.leafOffset
    },
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset + 62.37:
        this.doorTopHeight + this.leafOffset + 62.37
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth/2 - this.inoxOffset - 32.24/2 :
        this.doorWidth/2 - this.leafOffset - 32.24/2
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
  },
}
</script>
